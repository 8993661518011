





import {HttpRepository} from "@/services/HttpRepository";
import {Vue, Component} from 'vue-property-decorator';
import router from '@/router'
import defineAbilitiesFor from "@/libs/acl/ability";

@Component
export default class LoginRedirect extends Vue {
  created() {
    console.log(this.$route.query)
    if (this.$route.query.code) {
      console.log(this.$route.query.code)
      var response = HttpRepository.user
          .login(this.$route.query.code.toString())
          .then((response) => {
            const userData = response.data;
            console.log(JSON.stringify(userData))
            // Setting user data in localStorage
            localStorage.setItem("userData", JSON.stringify(userData.model));

            this.$ability.update(defineAbilitiesFor(userData.model).rules);
          })
          .finally(() => {
            this.$router.replace("/");
          });
    } else {
      this.$router.replace("/not-authorized");
    }
  }

}

